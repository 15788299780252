import React from "react"
import "./DiscountTop.scss"
import classNames from "classnames"
import discount from "./images/discount.png"

function DiscountTop({ type }) {
  const texts = {
    discounts: {
      title: "Allsports+",
      subtitle: "Дисконтная программа для клиентов сервиса Allsports",
      description:
        "Сервис Allsports – это не  только универсальный доступ в различные спортивные объекты, но и возможность получать дополнительные скидки при заказе товаров и услуг партнеров нашего сервиса!",
    },
  }
  const { title, subtitle, description } = texts[type]

  return (
    <section className={classNames("discounts-top", type)}>
      <img src={discount} alt="discounts" />
      <h1>{title}</h1>
      {subtitle && <h2>{subtitle}</h2>}
      <p>{description}</p>
    </section>
  )
}

export default DiscountTop
